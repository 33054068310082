<template lang="pug">
.w-100
  navs

  .content-height
    .bg-primary.text-center.text-white.p-2
      span Selecciona tus productos y solicita cotización

    router-view

  footer-component
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'
import FooterComponent from '@/components/Footer.vue'
import Navs from '@/components/Navs.vue'

export default {
  components: { ContactForm, FooterComponent, Navs },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.content-height {
  margin-top: 106px;
  min-height: calc(100vh - 106px - 177px);

  @include media-breakpoint-up(md) {
    margin-top: 112px;
    min-height: calc(100vh - 112px - 57.31px);
  }
}
</style>